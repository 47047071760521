import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsCheckboxAllSecondaryPreview(props) {
  const [checked, setChecked] = useState([false, false])

  const {
    FormElementsCheckboxSecondaryBasic,
    FormElementsCheckboxSecondaryDisabled,
    FormElementsCheckboxSecondaryHelperText,
  } = props

  const handleChange = toggle => {
    const updateCheckedState = checked.map((item, index) =>
      index === toggle ? !item : item
    )
    setChecked(updateCheckedState)
  }

  return (
    <>
      <div className="flex flex-col items-start gap-10 md:flex-row">
        <CopyComponent
          copyToClipboardCode={FormElementsCheckboxSecondaryBasic}
          componentName="FormElementsCheckboxSecondaryBasic"
          extraClasses="flex-1"
        >
          {/*  <!-- Component: Secondary basic checkbox  --> */}
          <div className="relative flex flex-wrap items-center">
            <input
              className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="checkbox"
              checked={checked[0]}
              onChange={() => handleChange(0)}
              id="id-c04"
            />
            <label
              className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="id-c04"
            >
              Secondary Checkbox
            </label>
            <svg
              className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-emerald-500 stroke-emerald-500 opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-hover:stroke-emerald-600 peer-focus:fill-emerald-700 peer-focus:stroke-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              aria-labelledby="title-01 description-01"
              role="graphics-symbol"
            >
              <title id="title-01">Check mark icon</title>
              <desc id="description-01">
                Check mark icon to indicate whether the radio input is checked
                or not.
              </desc>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
              />
            </svg>
          </div>
          {/*  <!-- End Secondary basic checkbox  --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={FormElementsCheckboxSecondaryHelperText}
          componentName="FormElementsCheckboxSecondaryHelperText"
          extraClasses="flex-1"
        >
          {/*  <!-- Component: Secondary checkbox with helper text --> */}
          <div className="relative flex flex-wrap items-center">
            <input
              className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="checkbox"
              checked={checked[1]}
              onChange={() => handleChange(1)}
              id="id-c06"
            />
            <label
              className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="id-c06"
            >
              With helper text
            </label>
            <small className="w-full py-2 pl-6 text-xs text-slate-400 transition peer-invalid:text-pink-500">
              <span>Checkbox can also have helper text</span>
            </small>
            <svg
              className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-emerald-500 stroke-emerald-500 opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-hover:stroke-emerald-600 peer-focus:fill-emerald-700 peer-focus:stroke-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              aria-labelledby="title-03 description-03"
              role="graphics-symbol"
            >
              <title id="title-03">Check mark icon</title>
              <desc id="description-03">
                Check mark icon to indicate whether the radio input is checked
                or not.
              </desc>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
              />
            </svg>
          </div>
          {/*  <!-- End Secondary checkbox with helper text --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={FormElementsCheckboxSecondaryDisabled}
          componentName="FormElementsCheckboxSecondaryDisabled"
          extraClasses="flex-1"
        >
          {/*  <!-- Component: Secondary disabled checkbox  --> */}
          <div className="relative flex flex-wrap items-center">
            <input
              className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-200 checked:hover:border-emerald-600 checked:hover:bg-emerald-300 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="checkbox"
              id="id-c05"
              disabled
            />
            <label
              className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor="id-c05"
            >
              Secondary Disabled
            </label>
            <svg
              className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-emerald-500 stroke-emerald-500 opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-hover:fill-emerald-600 peer-hover:stroke-emerald-600 peer-focus:fill-emerald-700 peer-focus:stroke-emerald-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              aria-labelledby="title-02 description-02"
              role="graphics-symbol"
            >
              <title id="title-02">Check mark icon</title>
              <desc id="description-02">
                Check mark icon to indicate whether the radio input is checked
                or not.
              </desc>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
              />
            </svg>
          </div>
          {/*  <!-- End Secondary disabled checkbox  --> */}
        </CopyComponent>
      </div>
    </>
  )
}
