import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsCheckboxAllPrimaryPreview from "../../../../library/components/form-elements/checkbox/react/_preview/primary"
import FormElementsCheckboxAllSecondaryPreview from "../../../../library/components/form-elements/checkbox/react/_preview/secondary"

import HeroCheckboxesComponent from "../../../../library/components/form-elements/checkbox/react/_preview/heroCheckboxesComponent"

// *** Checkbox Primary *** //

// Basic
const FormElementsCheckboxPrimaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/primary/basic.jsx")
const FormElementsCheckboxPrimaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/primary/basic.html")
// Disabled
const FormElementsCheckboxPrimaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/primary/disabled.jsx")
const FormElementsCheckboxPrimaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/primary/disabled.html")
// Helper text
const FormElementsCheckboxPrimaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/primary/helper_text.jsx")
const FormElementsCheckboxPrimaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/primary/helper_text.html")
// *** Checkbox Secondary *** //

// Basic
const FormElementsCheckboxSecondaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/secondary/basic.jsx")
const FormElementsCheckboxSecondaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/secondary/basic.html")
// Disabled
const FormElementsCheckboxSecondaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/secondary/disabled.jsx")
const FormElementsCheckboxSecondaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/secondary/disabled.html")
// Helper text
const FormElementsCheckboxSecondaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/checkbox/react/secondary/helper_text.jsx")
const FormElementsCheckboxSecondaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/checkbox/html/secondary/helper_text.html")

export default function FormElementCheckboxesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsCheckboxAllPrimary",
      title: "Primary Checkboxes",
      active_tab: 1,
    },
    {
      component_name: "FormElementsCheckboxesSecondary",
      title: "Secondary",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Checkboxes - WindUI Component Library"
        ogtitle="Tailwind CSS Checkboxes - WindUI Component Library"
        description="Checkbox components are used to let a user select one or more options of a limited number of choices. Built with Tailwind CSS by WindUI."
        ogdescription="Checkbox components are used to let a user select one or more options of a limited number of choices. Built with Tailwind CSS by WindUI."
        url="components/form-elements/checkboxes/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Checkboxes, Checkboxes, Checkbox components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Checkboxes</h1>
          <p>
            Checkboxes are used to let a user select one or more options of a
            limited number of choices. Checkboxes can turn an option on or off.
          </p>

          {/* Checkbox Preview Section */}
          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 leading-6 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <HeroCheckboxesComponent />
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Form Elements Checkbox Primary Section*/}
          <h3 id="FormElementsCheckboxAllPrimary">Primary Checkboxes</h3>

          <PreviewBlock
            id="FormElementsCheckboxAllPrimary"
            HtmlComponent={FormElementsCheckboxPrimaryBasicHTML.default}
            JsxComponent={FormElementsCheckboxPrimaryBasicJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesPreviewBlock="justify-center"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsCheckboxAllPrimaryPreview
                FormElementsCheckboxPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsCheckboxPrimaryBasicHTML.default
                    : FormElementsCheckboxPrimaryBasicJsx.default
                }
                FormElementsCheckboxPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsCheckboxPrimaryDisabledHTML.default
                    : FormElementsCheckboxPrimaryDisabledJsx.default
                }
                FormElementsCheckboxPrimaryHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsCheckboxPrimaryHelperTextHTML.default
                    : FormElementsCheckboxPrimaryHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="FormElementsCheckboxAllSecondary">Secondary Checkboxes</h3>

          <PreviewBlock
            id="FormElementsCheckboxAllSecondary"
            HtmlComponent={FormElementsCheckboxSecondaryBasicHTML.default}
            JsxComponent={FormElementsCheckboxSecondaryBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsCheckboxAllSecondaryPreview
                FormElementsCheckboxSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsCheckboxSecondaryBasicHTML.default
                    : FormElementsCheckboxSecondaryBasicJsx.default
                }
                FormElementsCheckboxSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsCheckboxSecondaryDisabledHTML.default
                    : FormElementsCheckboxSecondaryDisabledJsx.default
                }
                FormElementsCheckboxSecondaryHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsCheckboxSecondaryHelperTextHTML.default
                    : FormElementsCheckboxSecondaryHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use checkboxes to:</p>
          <ul>
            <li>Select one or more options from a list</li>
            <li>Present a list containing sub-selections</li>
            <li>Turn an item on or off in a desktop environment</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Checkboxes come in two basic styles (<strong>primary</strong> and{" "}
            <strong>secondary</strong>). Both have 1rem (16px) height and width
            and 0.5rem spacing between the button and the label.
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            In case of multiple grouped checkboxes, a{" "}
            <code>&lt;fieldset&gt;</code> should surround the entire group of
            checkboxes. The <code>&lt;legend&gt;</code>
            provides a description of the grouping.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
